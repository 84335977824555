.index-layout-reviews
  position: relative
  padding: 56px 0 24px
  text-align: center
  color: black
  background: white
  overflow: hidden

  .carousel__frame:focus
    outline: none

  .slider-controls__pager-item_active
    background: $text-accent

.index-layout-reviews__rating
  $font-size-large()
  color: $text-disabled

  &_active
    color: $text-accent

.index-layout-reviews__item
  max-width: 488px
  margin: 0 auto 40px
  padding: 0 8px

.index-layout-reviews__author
  $font-size-small()
  color: $text-secondary

.index-layout-reviews__text
  margin: 24px 0
  font-family: $font-kazimir
