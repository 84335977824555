.index-layout-screen
  position: relative
  flex: 0 0 298px
  width: 298px
  height: 605px
  margin: 0 32px
  padding-top: 80px
  box-sizing: border-box
  z-index: 5

  @media $bp.desktop
    margin: 0 64px

  @media $bp.mobile
    flex: 0 0 295px
    width: 295px
    height: 412px
    margin: 0 auto

    &_reader
      flex: 0 0 298px
      width: 298px
      margin-top: -42px
      padding-top: 12px

    &:not(&_reader)::after
      content: ''
      position: absolute
      left: -19px
      bottom: 1px
      width: 336px
      height: 2px
      background: #e8e8e8

.index-layout-screen__background
  position: absolute
  top: 0
  left: -50%
  transform: translateX(50%)
  z-index: -1

.index-layout-screen__shot
  width: 254px
  height: 453px

  @media $bp.mobile
    height: 330px

.index-layout-new-device
  &__wrapper
    display: inline-block
    position: relative
    margin: 80px 32px 0 32px
    box-sizing: border-box
    z-index: 5
    width: 351px
    height: 610px

    @media $bp.desktop
      margin: 80px 64px 0 64px

    @media $bp.mobile
      margin: 0 auto
      height: 410px
      &:not(&_reader)::after
        content: ''
        position: absolute
        left: 0
        bottom: 1px
        width: 336px
        height: 2px
        background: #e8e8e8

  &__device
    position: absolute
    transform: scale(0.6)
    top: -200px

    @media $bp.mobile
      height: 780px
      width: 545px
      top: -180px

  &__content
    position: absolute
    transform: scale(0.5)
    top: -221px

    @media $bp.mobile
      top: -191px
      left: -74px

.index-section
  .index-layout-new-device__device
    left: -85px
  .index-layout-new-device__content
    left: -56px
    @media $bp.mobile
      left: -77px

.index-section_mirrored
  .index-layout-new-device__device
    left: -125px
    @media $bp.mobile
      left: -85px
  .index-layout-new-device__content
    left: -99px
    @media $bp.mobile
      left: -77px
