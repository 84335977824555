.index-layout-features
  display: flex
  justify-content: space-around
  padding: 24px 0

  @media $bp.mobile
    display: block
    padding: 0

.index-layout-features__content
  max-width: 298px
  text-align: left

  @media $bp.mobile
    margin: 0 auto 64px
  
    &:last-child
      margin-bottom: 32px

  @media $bp.desktop
    max-width: 480px

.index-layout-features__title
  $landing-section-title()

.index-layout-features__text
  padding-right: 24px
  font-family: $font-kazimir

.index-layout-features__emoji
  display: inline-block
  width: 60px
  height: 60px
  margin: 0 24px 24px 0
  border-radius: 50%
  background: white url('./emoji-sprite.png') center no-repeat
  background-size: 57px 241px

  &_1
    background-position: 6px -186px

  &_2
    background-position: 4px 8px

  &_3
    background-position: 4px -90px

  &_4
    background-position: 4px -138px

  &_5
    background-position: 4px -40px
