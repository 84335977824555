.mtn-button {
  width: 100%
  border-radius: 28px

  &, &:not(.button_disabled):not(.button_loading):hover {
    color: #22201F
    background-color: #FFCB05
    border: 1px solid transparent;
  }

  &.button_disabled {
    color: #fff
    background-color: #EDEDED
  }
}



