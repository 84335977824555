$column(n)
  width: ((64 * n) + (16 * (n - 1)))px
  margin-left: 8px
  margin-right: 8px

$font-size-xxxx-large()
  font-size: 63px
  line-height: 70px

$font-size-xxx-large()
  font-size: 50px
  line-height: 59px

$font-size-xx-large()
  font-size: 34px
  line-height: 40px

  @media $bp.mobile
    font-size: 24px
    line-height: 32px

$font-size-xx-large_no-mobile()
  font-size: 34px
  line-height: 40px

$font-size-x-large()
  font-size: 24px
  line-height: 32px

$font-size-large()
  font-size: 20px
  line-height: 24px

$font-size-medium()
  font-size: 16px
  line-height: 24px

$font-size-small()
  font-size: 14px

$font-size-x-small()
  font-size: 12px

$font-size-xx-small()
  font-size: 10px
  line-height: 12px

$btn-primary()
  display: block
  position: relative
  width: 100%
  border-radius: 4px
  text-align: center
  min-height: 48px
  font: 1rem/1.4rem Museo, Arial, sans-serif
  font-weight: bold
  border: 1px solid transparent
  opacity: 1
  background: $text-accent
  color: white
  padding: 12px 16px
  box-sizing: border-box
  margin: 0
  cursor: pointer
  transition: background-color 0.3s
  text-decoration: none
  overflow: visible

  &:not(&_disabled):not(&_loading):hover
    color: white
    background-color: spin($text-accent, 10deg)

$btn-huge()
  display: inline-block
  min-height: 56px
  width: auto
  min-width: 200px
  padding: 16px 32px

$card()
  padding: 24px
  border-radius: 4px

  @media $bp.mobile
    padding: 16px

$card-dark()
  $card()
  color: white
  background: $base-card-dark
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.80)

  @media $bp.mobile
    padding: 16px

$card-light()
  $card()
  background: white
  box-shadow: 0 1px 0 0 $base-line-secondary

$clean-button()
  padding: 0
  margin: 0
  border: none
  outline: none
  background: none

$card-plan()
  $font-size-medium()
  width: 272px
  margin: 0 8px 16px 8px
  position: relative
  display: flex
  flex-direction: column
  box-sizing: border-box
  min-height: 408px
  padding-bottom: 32px
  color: $base-line-primary
  background: white
  border-radius: 4px
  box-shadow: 0 1px 0 0 $base-line-secondary
  align-items: center

$card-plan-top()
  display: flex
  width: 100%
  height: 64px
  margin-bottom: 24px
  align-items: center
  justify-content: center
  border-top-left-radius: 4px
  border-top-right-radius: 4px

$plan-card-header()
  $font-size-x-large()
  font-weight: bold
  position: relative
  box-sizing: border-box
  padding: 0 16px
  margin: 24px 0 16px 0
  text-align: center
  line-height: 1.2em

$link-underline()
  text-decoration: none
  border-bottom: 1px solid $base-line-primary

  &:hover
    border-color: $text-accent

$link-underline-on-hover()
  text-decoration: none
  border-bottom: 1px solid transparent

  &:hover,
  &_active
    color: $text-accent
    border-bottom: 1px solid $text-accent

$link()
  cursor: pointer
  text-decoration: none
  border-bottom: 1px solid $base-line-primary

  &:hover,
  &_active
    color: $text-accent
    border-bottom: 1px solid $text-accent

$link-without-underline()
  cursor: pointer
  text-decoration: none

  &:hover,
  &_active
    color: $text-accent

$landing-section-title()
  $font-size-large()
  margin: 0 0 24px
  line-height: 1.1

  @media $bp.desktop, $bp.desktopMedium, $bp.desktopLarge
    $font-size-x-large()

$landing-section-content()
  max-width: 298px
  margin: 40px auto
  text-align: left

  @media $bp.tablet
    margin: 40px 32px

  @media $bp.desktop, $bp.desktopMedium, $bp.desktopLarge
    max-width: 480px
    margin: 40px 0

// GRID VIEW

$grid-view()
  display: inline-block
  vertical-align: top

$grid-view-title()
  $font-size-small()
  text-align: left
  line-height: 20px
  font-weight: bold
  max-height: 40px
  overflow-y: hidden

$grid-view-description()
  margin: 8px 0 0
  white-space: normal

$grid-view-reading-status()
  position: absolute
  width: 100%
  top: -24px
  height: 24px

// LIST VIEW

$list-view()
  display: flex
  align-items: center
  width: 100%
  box-sizing: border-box
  background-color: white
  padding: 24px 16px 24px 16px
  border-radius 4px
  box-shadow: 0 1px 0 0 $base-line-secondary

  .plus
    flex-grow: 0
    flex-shrink: 0

  @media $bp.mobile
    padding-left: 8px
    padding-right: 0

$list-view-title()
  $font-size-medium()
  line-height: 20px
  font-weight: bold
  max-height: 40px
  overflow-y: hidden

$list-view-info()
  margin-left: 16px
  flex: 1 1 auto

  @media $bp.mobile
    margin-left: 8px

$list-view-title-link() // maybe not only list-view
  &:before
    content: ''
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0

$list-view-reading-status()
  position: absolute
  top: 16px
  right: 0px
  width: 64px

$position-label()
  $font-size-xx-small()
  position: absolute
  top: 8px
  left: 8px
  width: 18px
  height: 18px
  color: white
  line-height: 18px
  text-align: center
  background-color: rgba(black, 0.6)
  border-radius: 4px
  z-index: 2

$play-icon()
  position: absolute
  left: 8px
  bottom: 8px
  width: 24px
  height: @width
  fill: $action-audio
  z-index: 1

  @media $bp.mobile
    left: 4px
    bottom: 4px
    width: 16px
    height: @width

$bubble()
  $font-size-small()
  display: block
  padding: 8px 16px
  line-height: 20px
  background: #fff
  border-radius: 4px

$reset-block-margins()
  margin-block-start: 0
  margin-block-end: 0

$center-element(xAxis = center)
  display: grid
  place-items: center xAxis
