.header-mtn {
  height: 56px;
  background: #FFCB05;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-width: 624px;
    margin: 0 auto;
    padding: 0 10px;
    box-sizing: border-box;
  }

  &__logo svg {
    @media $bp.mobile {
      display: block;

      g {
        visibility: hidden
      }
    }
  }

  &__languages {
    position: relative;
    margin-left: auto;
  }
}
