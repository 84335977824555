.index-footer
  padding: 40px 0 32px
  color: rgba(white, 0.6)
  background: rgba(#474747, 0.8)

  a
  .footer__links
  .copyright
  .legal-notice
  .legal-notice a
    color: rgba(white, 0.6)

  a:hover
    color: white
    border-color: white

  .store-button
  .social-link__icon
    svg
      fill: rgba(white, 0.6)

    &:hover svg
      fill: white
