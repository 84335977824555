.index-section
  padding: 64px 16px
  color: black
  background: white
  overflow: hidden

  @media $bp.tablet
    padding: 64px 24px

  @media $bp.mobile
    padding: 40px 8px 24px

  &_flex
    .index-section__wrapper
      display: flex
      flex-direction: row-reverse
      align-items: center
      justify-content: space-between

      @media $bp.tablet
        justify-content: space-around

      @media $bp.mobile
        display: block

  &_mirrored
    @extend .index-section_flex
    margin-top: -64px
    padding-top: 0

    .index-section__wrapper
      flex-direction: row

    @media $bp.mobile
      margin-top: 0

.index-section__wrapper
  position: relative
  width: 100%
  max-width: $layout-max-width
  margin: 0 auto
