.emotions-carousel
  position: relative
  max-width: 420px
  height: 34px
  margin-bottom: 24px
  overflow: hidden

  @media $bp.mobile
    overflow: visible

.emotions-carousel__container
  display: flex

  &::after
    content: ''
    position: absolute
    top: 0
    right: 0
    width: 104px
    height: 100%
    background-image: linear-gradient(to left, #fbeeea, rgba(251, 238, 234, 0.0))
    z-index: 20

  @media $bp.mobile
    position: absolute

.emotions-carousel__emotion
  $font-size-xx-small()
  flex: 0 0 auto
  height: 32px
  margin: 0 8px 10px 0
  padding: 0 12px
  line-height: 32px
  font-weight: bold
  text-transform: uppercase
  color: white
  border-radius: 100px
  user-select: none

  &_dart
    background-color: #81d4c1

  &_thumbsup
    background-color: #ffd900

  &_zzz
    background-color: #cfd3e6

  &_rocket
    background-color: #f74e45

  &_heart
    padding: 0

  &_panda
    background-color: #74ba74

  &_droplet
    background-color: #cbdcf0

.emotions-carousel__icon
  $font-size-small()
  margin-right: 4px
  vertical-align: middle

  &_thumbsup
    margin-right: 0
