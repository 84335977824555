.index-layout-upload__content
  max-width: 480px
  margin: 24px auto
  text-align: center

.index-layout-upload__title
  $landing-section-title()

.index-layout-upload__text
  font-family: $font-kazimir

.index-layout-upload__diagram
  $font-size-medium()
  position: relative
  margin: 8px auto 64px
  line-height: 48px

  @media $bp.mobile
    max-width: 320px

.index-layout-upload__step
  display: inline-block
  margin-top: 16px
  vertical-align: bottom
  text-align: center

  @media $bp.mobile
    display: block

  &_second
    display: inline-block
    width: 119px
    height: 74px
    padding: 0
    background: url('./cloud.png') center no-repeat
    background-size: 119px 74px

    @media $bp.mobile
      margin: 56px auto 48px

.index-layout-upload__step-content
  padding: 16px 20px
  vertical-align: middle
  line-height: 1
  background: white
  border-radius: 100px

.index-layout-upload__arrow
  display: inline-block
  margin: 0 16px
  vertical-align: middle

  @media $bp.mobile
    position: absolute

    &_left
      top: 64px
      left: 48px
      transform: scale(-1, 1)

    &_right
      top: 168px
      right: 52px
