.index-layout-media__content
  $landing-section-content()

.index-layout-media__title
  $landing-section-title()

.index-layout-media__text
  font-family: $font-kazimir

.index-layout-media__logos
  width: 984px
  margin-top: 40px

  @media $bp.mobile
    margin-top: 48px
    margin-left: -20px

  img
    width: 100%
    height: auto
