.mtn-layout
  width: 100%
  overflow: hidden
  text-align: center
  background-color: white
  font-family: $font-graphik
  font-size: 17px

.mtn-common-layout
  padding: 64px 16px 16px
  font-family: $font-graphik
  font-size: 17px
  background: #F4F2EF
  max-width: 620px
  margin: auto

.mtn-lead-in
  display: flex
  align-items: center
  justify-content: center
  height: 740px
  color: black
  background: url('./assets/desktop-left.png') no-repeat left 48px / 35%, url('./assets/desktop-right.png') no-repeat right 48px / 35%, #37BE8C
  @media $bp.tablet, $bp.mobile
    height: 762px
  @media $bp.tablet
    background: url('./assets/tablet.png') no-repeat 0 -64px / contain #37BE8C
  @media $bp.mobile
    background: url('./assets/mobile.png') no-repeat 0 -34px / contain #37BE8C

.form-wrapper
  position: relative
  .loader-wrapper
    position: absolute
    inset 0
    background-color #37BE8C66
    display flex
    justify-content center
    align-items center

.mtn-column
  width: 413px
  padding: 16px
  @media $bp.tablet, $bp.mobile
    padding-top: 250px

.mtn-heading
  font-size: 32px !important
  line-height: 120% !important

.mtn-subheading
  font-weight: 600
  font-size: 16px
  line-height: 22px

.mtn-caption
  font-weight: 400
  font-size: 13px
  color: rgba(0, 0, 0, 0.45)

.mtn-text
  font-weight: 400
  font-size: 17px
  color: #22201F

.mtn-list
  &__item
    margin-bottom: 4px

  &__number
    display: inline-block
    width: 28px
    line-height: 28px
    margin-right: 8px
    border: 1px solid rgba(0, 0, 0, 0.08)
    border-radius: 50%
    text-align: center

.join-label,
.mtn-not-a-user
  display: block
  font-size: 16px
  font-weight: 600
  text-decoration: underline
  line-height: 1.2
  color: #fff

  &:active, &:visited, &:hover
    color: #fff

.join-label
  text-decoration: none
